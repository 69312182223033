import React from 'react';
import Blog from '../components/Blog';
import Layout from '../components/layouts/en';

const IndexPage = ({ location, ...rest }) => (
  <Layout location={location}>
    <Blog location={location} />
  </Layout>
);

export default IndexPage;
